const HomeLanding = () => {
    return (
        <section id="landing">
            <div className="container-fluid">
                <h1 className="header">
                    <span className="gold"> Quality </span> through 
                    <span className="d-block"> Innovation </span>
                </h1>
                <h2>
                    One of the Largest Feed Manufacturer 
                    <p> in the Philippines </p>
                </h2>
                <a className="btn" href="https://supplier.gensanfeedmill.com/" target="_blank" rel="noreferrer noopener">Become a Supplier</a>
            </div>
        </section>
    )
}

export default HomeLanding
