import {NavHashLink} from 'react-router-hash-link'
import farmer1 from '../../assets/images/about/farmer1.png'
import farmer2 from '../../assets/images/about/farmer2.png'
import farmer3 from '../../assets/images/about/farmer3.png'

const JoinUs = () => {
    return (
        <section id="joinUs">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-7">
                        <div className="row">
                            <div className="col-4">
                                <img src={farmer1} alt="Farmer 1" className="img-fluid"/>
                            </div>
                            <div className="col-4">
                                <img src={farmer2} alt="Farmer 2" className="img-fluid"/>
                            </div>
                            <div className="col-4">
                                <img src={farmer3} alt="Farmer 3" className="img-fluid"/>
                            </div>
                            <div className="col-4">
                                <img src={farmer2} alt="Farmer 2" className="img-fluid"/>
                            </div>
                            <div className="col-4">
                                <img src={farmer3} alt="Farmer 3" className="img-fluid"/>
                            </div>
                            <div className="col-4">
                                <img src={farmer1} alt="Farmer 1" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5">
                        <h1 className="subhead">COUNT ON US</h1>
                        <p className="boldPara">We value our <span> Clients and Suppliers </span> more than our business</p>
                        <p className="generalPara">We are committed to a better future and our willingness to adapt to changes is what keeps us motivated and competitive.</p>
                        <div className="joinNow">
                            <NavHashLink to='/contact#header' className="navJoin">
                                <i className="fas fa-arrow-circle-right fa-3x"></i>
                                <p>Join us now</p>
                            </NavHashLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default JoinUs
