import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import HomePage from './components/Home/HomePage';
import AboutPage from './components/About/AboutPage';
import ContactPage from './components/Contact/ContactPage';
import './App.scss';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Route exact path="/" component={HomePage}/>
      <Route exact path="/about" component={AboutPage}/>
      <Route exact path="/contact" component={ContactPage}/>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
