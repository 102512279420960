import mission from '../../assets/images/about/mission.png'

const MissionVision = () => {
    return (
        <section id="missionVision">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 d-md-block d-none">
                        <img src={mission} alt="Mission and Vision" className="img-fluid"/>
                    </div>
                    <div className="col-md-6">
                        <h2 className="subhead">Mission <span> and </span> Vision</h2>
                        <p className="generalPara borderSub">The <span> Largest Distributor </span> of Feeds in SouthEast Asia and China</p>
                        <p className="generalPara">To constantly improve the food production industry towards optimal nutrition, efficiency, and health for animals and people.</p>
                    </div>
                    <div className="col-md-6 d-md-none d-block">
                        <img src={mission} alt="Mission and Vision" className="img-fluid"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MissionVision
