

const CoreValues = () => {
    return (
        <section id="coreValues">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 integrity">
                        <h1>INTEGRITY</h1>
                        <p>We all have a moral responsibility to the company and all of its stakeholders.</p>
                        <p>We are transparent about our intentions and put the good of the company before our own personal agenda or gains.</p>
                        <p>Integrity is translated through everything we do, including our products. We sell only what we believe to be the best quality possible.</p>
                    </div>
                    <div className="col-md-4 quality">
                        <h1>QUALITY</h1>
                        <p>The standards we set for ourselves and our stakeholders are high.</p>
                        <p>We strive for excellence throughout all levels of the company, from our product to our processes.</p>
                        <p>We always meet or exceed the standards set by our customers, always keeping in mind the needs of the animals and people we serve.</p>
                        <p>Competition drives us to constantly learn and improve, setting the bar higher and higher over time.</p>
                    </div>
                    <div className="col-md-4 flexibility">
                        <h1>FLEXIBILITY</h1>
                        <p>Our willingness to adapt to changes is what keeps us motivated and competitive.</p>
                        <p>We seek solutions quickly and effectively when a challenge comes our way.</p>
                        <p>We dive deep into the industry to be prepared and adjust to any changes in the business climate or technological advancements.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CoreValues
