import AboutLanding from './AboutLanding'
import AboutContent from './AboutContent'
import MissionVision from './MissionVision'
import Stats from './Stats'
import CoreValues from './CoreValues'
import JoinUs from './JoinUs'

const AboutPage = () => {
    return (
        <>
            <AboutLanding />
            <AboutContent />
            <MissionVision />
            <Stats />
            <CoreValues />
            <JoinUs />
        </>
    )
}

export default AboutPage
