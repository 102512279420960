import React from 'react'
import cpf from '../../assets/images/home/cpf.png'
import pigrolac from '../../assets/images/home/pigrolac.png'
import bmeg from '../../assets/images/home/bmeg.png'
import magnolia from '../../assets/images/home/magnolia.png'
import purina from '../../assets/images/home/purina.png'
import smc from '../../assets/images/home/smc.png'

const OurClient = () => {
    return (
        <section id="ourClient">
            <div className="container">
                
                <h1 className="titleHead">OUR <span> CLIENTS </span></h1>
                    
                <div className="row companyLogo d-none">
                    <div className="col-md-2 col-4 d-md-none d-block">
                        <img src={magnolia} alt="magnolia Logo" className="img-fluid"/>
                    </div>
                    <div className="col-md-2 col-4 d-md-block d-none">
                        <img src={cpf} alt="cpf Logo" className="img-fluid p-4"/>
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={pigrolac} alt="pigrolac Logo" className="img-fluid"/>
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={bmeg} alt="bmeg Logo" className="img-fluid"/>
                    </div>
                    <div className="col-md-2 col-4 d-md-block d-none">
                        <img src={magnolia} alt="magnolia Logo" className="img-fluid"/>
                    </div>
                    <div className="col-md-2 col-4 d-md-none d-block">
                        <img src={cpf} alt="cpf Logo" className="img-fluid p-4"/>
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={purina} alt="purina Logo" className="img-fluid"/>
                    </div>
                    <div className="col-md-2 col-4">
                        <img src={smc} alt="smc Logo" className="img-fluid"/>
                    </div>
                </div>
            </div>
            
        </section>
    )
}

export default OurClient
