import vid from '../../assets/images/vid.mp4'

const Video = () => {
    return (
        <section id="video">
            <div className="container-fluid">
                <video autoPlay playsInline muted loop className="embed-responsive">
                    <source src={vid} type="video/mp4" className="embed-responsive-item"/>
                </video>
            </div>
        </section>
    )
}

export default Video
