import {NavLink} from 'react-router-dom'
import {NavHashLink} from 'react-router-hash-link'
import {useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import logo from '../assets/images/logo.png'
import scrollUp from '../assets/images/scrollUp.png'


const Footer = () => {

    const [removeMargin, setRemoveMargin] = useState(false)
    const location = useLocation()

    useEffect(() => {
        if(location.pathname === '/contact') {
            setRemoveMargin(true);
        } else {
            setRemoveMargin(false);
        }
    }, [location])

    return (
        <footer id="footer" className={removeMargin ? 'active' : ''}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-4">
                        <div className="logo">
                            <img src={logo} alt="LOGO" className="img-fluid"/>
                        </div>
                        <div className="socmed">
                            <h1>Follow Us On</h1>
                            <div className="row">
                                <div className="col-1">
                                    <NavLink to="#" target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-facebook-square"></i>
                                    </NavLink>
                                </div>
                                <div className="col-1">
                                    <NavLink to="#" target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-linkedin"></i>
                                    </NavLink>
                                </div>
                                <div className="col-1">
                                    <NavLink to="#" target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-instagram"></i>
                                    </NavLink>
                                </div>
                                <div className="col-1">
                                    <NavLink to="#" target="_blank" rel="noopener noreferrer">
                                        <i className="fab fa-twitter"></i>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-md-4 links">
                        <h1>Links</h1>
                        <NavHashLink activeClassName="selected" className="nav-link" exact to="/#header">Home</NavHashLink>
                        <NavHashLink activeClassName="selected" className="nav-link" exact to="/about#header">About</NavHashLink>
                        <NavHashLink activeClassName="selected" className="nav-link" exact to="/contact#header">Contact</NavHashLink>
                        <NavHashLink className="nav-link disabled" to="#" tabIndex="-1" aria-disabled="true">Supplier</NavHashLink>
                    </div>
                    <div className="col-xl-2 col-md-4 products">
                        <h1>Products</h1>
                        <p>Feeds</p>
                        <p>Pellets</p>
                        <p>Fertilizer</p>
                        <p>Milk</p>
                        <p>Energy Drink</p>
                        <p>Eggs</p>
                    </div>
                    <div className="col-xl-4 col-md-4 contact">
                        <h1>Contact</h1>
                        <p>(083) 225-2853</p>
                        <p>services@gensanfeedmill.com</p>
                        <p>Brgy. Silway 7, Purok Matin-ao, Polomolok, South Cotabato</p>
                    </div>
                </div>
                <div className="row footer">
                    <div className="col-xl-4 col-md-4">
                        &copy; 2021 | Classify Inc. | All rights reserved.
                    </div>
                    <div className="col-xl-2 offset-xl-2 col-md-2">
                        Privacy Policy
                    </div>
                    <div className="col-xl-2 col-md-3">
                        Terms and Conditions
                    </div>
                    <div className="col-xl-2 col-md-3 text-right scrollUp">
                        <a href="#header">
                            <img src={scrollUp} alt="Scroll Up"/>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
