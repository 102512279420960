import HomeLanding from './HomeLanding'
import OurClient from './OurClient'
import Farmers from './Farmers'
import WeAreDifferent from './WeAreDifferent'
import Video from './Video'
import WeStandFor from './WeStandFor'
import Feature from './Feature'

const HomePage = () => {
    return (
        <>
            <HomeLanding />  
            <OurClient />  
            <Farmers />
            <WeAreDifferent />
            <Video />
            <WeStandFor />
            <Feature />
        </>
    )
}

export default HomePage
