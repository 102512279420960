import ContactLanding from './ContactLanding'
// import Map from './Map'

const ContactPage = () => {
    return (
        <>
           <ContactLanding />
           {/* <Map /> */}
        </>
    )
}

export default ContactPage
