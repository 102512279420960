import {useState} from 'react'
import {NavHashLink} from 'react-router-hash-link'
import {useLocation} from 'react-router-dom'
import Logo from '../assets/images/logo.png'
import waffle from '../assets/images/waffleGreen.png'

const Header = () => {

    const [colorChange, setColorChange] = useState(true)
    const location = useLocation()

    // window.addEventListener('resize', () => {
    //     let width = window.innerWidth;
    //     if (width > 1024) {

    //     }
    // })

    window.addEventListener('scroll', () => {
        switch (location.pathname) {
            // case '/contact' :
            //     if (window.scrollY >= 100) {
            //         setColorChange(false)
            //     } else {
            //         setColorChange(true)
            //     } break;
            // case '/about' :
            //     if (window.scrollY >= 200) {
            //         setColorChange(false)
            //     } else {
            //         setColorChange(true)
            //     } break;
            default:
                if (window.scrollY >= 100) {
                    setColorChange(false)
                } else {
                    setColorChange(true)
                } break;

        }
    })


    return (
        <header id="header">
            <nav className={`navbar fixed-top navbar-expand-md ${!colorChange && 'active'}`}>
                <div className="container-fluid">
                    <NavHashLink className="navbar-brand" to="/#header">
                        <img src={Logo} alt="Gensan Feedmill"/>
                    </NavHashLink>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon">
                            <img src={waffle} alt="App" className="img-fluid"/>
                        </span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav ml-auto">
                            <NavHashLink activeClassName="selected" className="nav-link" exact to="/#header">HOME</NavHashLink>
                            <NavHashLink activeClassName="selected" className="nav-link" exact to="/about#header">ABOUT</NavHashLink>
                            <NavHashLink activeClassName="selected" className="nav-link" exact to="/contact#header">CONTACT</NavHashLink>
                            <a className="nav-link font-weight-bold supplier" href="https://supplier.gensanfeedmill.com/" target="_blank" rel="noreferrer noopener">SUPPLIER</a>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header
