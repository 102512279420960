import React from 'react'

const Stats = () => {
    return (
        <section id="stats">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4">
                        <h1>80</h1>
                        <h2>ACTIVE SUPPLIERS</h2>
                    </div>
                    <div className="col-md-4 col-6">
                        <h1>105</h1>
                        <h2>HAPPY CLIENTS</h2>
                    </div>
                    <div className="col-md-4 col-6 lastStat">
                        <h1>69</h1>
                        <h2>PROJECTS DONE</h2>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Stats
