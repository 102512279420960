import innovation from '../../assets/images/home/innovation.png'
import network from '../../assets/images/home/network.png'
import animal from '../../assets/images/home/animal.png'

const WeAreDifferent = () => {
    return (
        <section id="weAreDifferent">
            <div className="container">
                
                <h1 className="titleHead">WE ARE <span> DIFFERENT </span></h1>

                <div className="row">
                    {/* Innovation */}
                    <div className="col-md-6 d-block d-md-none innovation">
                        <img src={innovation} alt="Innovation" className="img-fluid"/>
                    </div>
                    <div className="col-md-6">
                        <h2 className="subHead"> <span> ADVANCE </span> TECH FACILITIES</h2>
                        <div className="content">
                            <p className="generalPara">We invest in research and development to make sure our products are always top quality and our facilities are always state of the art.</p>
                        </div>
                    </div>
                    <div className="col-md-6 d-none d-md-block innovation">
                        <img src={innovation} alt="Innovation" className="img-fluid"/>
                    </div>
                    {/* Network */}
                    <div className="col-md-6 network">
                        <img src={network} alt="Network" className="img-fluid"/>
                    </div>
                    <div className="col-md-6">
                        <h2 className="subHead"> VERTICAL <span> INTEGRATION </span> </h2>
                        <div className="content">
                            <p className="generalPara">We are not only involved in the feed mill business, We also own businesses at several levels of the supply chain that enables us to leverage for the optimisation of the industry from end to end.</p>
                        </div>
                    </div>
                    {/* Care for Animals */}
                    <div className="col-md-6 d-md-none d-block animalCare">
                        <img src={animal} alt="Animal" className="img-fluid"/>
                    </div>
                    <div className="col-md-6">
                        <h2 className="subHead"> <span> CARE </span> FOR ANIMALS</h2>
                        <div className="content">
                            <p className="generalPara">Unlike many feed suppliers, we take a hands-on approach to improve the health and performance of the animals we feed.</p>
                        </div>
                    </div>
                    <div className="col-md-6 d-md-block d-none">
                        <img src={animal} alt="Animal" className="img-fluid"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WeAreDifferent

