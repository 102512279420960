import ecofriend from '../../assets/images/home/ecofriend.png'
import innovate from '../../assets/images/home/innovate.png'
import lowcost from '../../assets/images/home/lowcost.png'
import animalfriend from '../../assets/images/home/animalfriend.png'

const Feature = () => {
    return (
        <section id="feature">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-6">
                        <figure>
                            <img src={ecofriend} alt="Eco Friendly" className="img-fluid"/>
                            <figcaption>Eco-Friendly</figcaption>
                        </figure>
                    </div>
                    <div className="col-lg-3 col-6">
                        <figure>
                            <img src={innovate} alt="Innovative" className="img-fluid"/>
                            <figcaption>Innovative</figcaption>
                        </figure>
                    </div>
                    <div className="col-lg-3 col-6">
                        <figure>
                            <img src={lowcost} alt="Low Cost" className="img-fluid"/>
                            <figcaption>Low Cost</figcaption>
                        </figure>
                    </div>
                    <div className="col-lg-3 col-6">
                        <figure>
                            <img src={animalfriend} alt="Animal Friendly" className="img-fluid"/>
                            <figcaption>Animal Friendly</figcaption>
                        </figure>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Feature
