const Map = () => {
    return (
        <section id="map">
            <div className="mapContainer">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.682151890686!2d125.10635211525077!3d6.173292828846876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x32f79b585237fe15%3A0x341ea1e3e21cd90e!2sGensan%20Feedmill%2C%20Inc.!5e0!3m2!1sen!2sph!4v1618284754179!5m2!1sen!2sph" width="600" height="450" title="gensanfeedmillmap" allowFullScreen="" loading="lazy"></iframe>
            </div>
        </section>
    )
}

export default Map
