

const AboutLanding = () => {
    return (
        <section id="aboutLanding">
            <div className="container-fluid">
                <h1 className="header">
                    <span className="gold"> About </span> 
                    <span> Us </span>
                </h1>
            </div>
        </section>
    )
}

export default AboutLanding
