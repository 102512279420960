import westand from '../../assets/images/home/westand.png'
import westandicons from '../../assets/images/home/westandicons.png'

const WeStandFor = () => {
    return (
        <section id="weStand">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <img src={westand} alt="3 Stand for" className="img-fluid weStandImage"/>
                    </div>
                    <div className="col-md-6 weStand">
                        <h1 className="titleHead">WHAT WE <span> STAND </span> FOR</h1>
                        <img src={westandicons} alt="icons" className="img-fluid weStandIcon"/>
                        <p className="generalPara">To constantly improve feed production towards optimal nutrition, effeciency and health for animals and people.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WeStandFor
