import {useState} from 'react'
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2'
import phone from '../../assets/images/contact/phone.png'
import email from '../../assets/images/contact/email.png'
import location from '../../assets/images/contact/location.png'
import Map from './Map'

const ContactLanding = () => {

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [emailAd, setEmailAd] = useState('')
    const [phoneNum, setPhoneNum] = useState('')
    const [message, setMessage] = useState('')

    const [firstNameError, setFirstNameError] = useState(false)
    const [lastNameError, setLastNameError] = useState(false)
    const [emailAdError, setEmailAdError] = useState(false)
    const [phoneNumError, setPhoneNumError] = useState(false)
    const [messageError, setMessageError] = useState(false)

    let error = 0

    let templateParams = {
        firstName :  firstName,
        lastName :  lastName,
        email : emailAd,
        number : phoneNum,
        message : message
    }

    const sendEmail = (templateParams) => {
        emailjs.send('gmail', 'template_biaccpw', templateParams, 'user_Kq6itYzxecdKa4t8X4Lxg')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      }
    
    const validate = (e) => {
        e.preventDefault()

        if (!firstName) {
            setFirstNameError(true)
            error++
        } else {
            setFirstNameError(false)
        }

        if (!lastName) {
            setLastNameError(true)
            error++
        } else {
            setLastNameError(false)
        }

        if (!emailAd.includes('@' && '.com')) {
            setEmailAdError(true)
            error++
        } else {
            setEmailAdError(false)
        }

        if (!phoneNum) {
            setPhoneNumError(true)
            error++
        } else {
            setPhoneNumError(false)
        }

        if (!message) {
            setMessageError(true)
            error++
        } else {
            setMessageError(false)
        }

        if (error > 0) {

            let timerInterval
            Swal.fire({
                // title: 'Something is not right!',
                html: '<span style="font-size:1.5rem">Something is not right<span/>',
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading()
                    timerInterval = setInterval(() => {
                    const content = Swal.getContent()
                    if (content) {
                        const b = content.querySelector('b')
                        if (b) {
                        b.textContent = Swal.getTimerLeft()
                        }
                    }
                    }, 100)
                },
                willClose: () => {
                    clearInterval(timerInterval)
                }
                }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                    // console.log('I was closed by the timer')
                }
            })

        } else {

            sendEmail(templateParams)
            
            let timerInterval
            Swal.fire({
                // title: 'Message Sent!',
                html: '<span style="font-size:1.5rem; color:#699e3c">Message sent successfully!<span/>',
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading()
                    timerInterval = setInterval(() => {
                    const content = Swal.getContent()
                    if (content) {
                        const b = content.querySelector('b')
                        if (b) {
                        b.textContent = Swal.getTimerLeft()
                        }
                    }
                    }, 100)
                },
                willClose: () => {
                    clearInterval(timerInterval)
                }
                }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                    // console.log('I was closed by the timer')
                }
            })

            setFirstName('')
            setLastName('')
            setEmailAd('')
            setPhoneNum('')
            setMessage('')
        }
    }

    

    return (
        <section id="contactLanding">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 headContainer">
                        <h1 className="header"> <span> Get in <span className="gold">Touch</span></span></h1>
                    </div>
                    <div className="col-xl-5 genContainer">
                        <h2 className="subhead"> <span> General Inquiries </span></h2>
                        <div className="phone">
                            <div className="img-container">
                                <img src={phone} alt="Phone Logo" className="img-fluid"/>
                            </div>
                            <p className="generalPara">(083) 225-2853</p>
                        </div>
                        <div className="email">
                        <div className="img-container">
                            <img src={email} alt="Email Logo" className="img-fluid"/>
                        </div>
                            <p className="generalPara">services@gensanfeedmill.com</p>
                        </div>
                        <div className="location">
                        <div className="img-container">
                            <img src={location} alt="Location Logo" className="img-fluid"/>
                        </div>
                            <p className="generalPara">Brgy. Silway 7, Purok Matin-ao, Polomolok, South Cotabato</p>
                        </div>
                    </div>
                    <div className="col-xl-7 formContainer">

                        <form onSubmit={validate}>
                            <div className="form-row form-group">
                                <div className="col-md-6 form-group">
                                    <input 
                                        type="text" 
                                        placeholder="First name"
                                        className={`form-control ${firstNameError ? 'is-invalid' : 'is-valid'}`}
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                    {/* <div class="valid-feedback">
                                        Looks good!
                                    </div>
                                    <div class="invalid-feedback">
                                        There is something wrong
                                    </div> */}
                                </div>
                                <div className="col-md-6 form-group">
                                    <input 
                                        type="text" 
                                        placeholder="Last name"
                                        className={`form-control ${lastNameError ? 'is-invalid' : 'is-valid'}`}
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-6 form-group">
                                    <input 
                                        type="email" 
                                        placeholder="Email"
                                        className={`form-control ${emailAdError ? 'is-invalid' : 'is-valid'}`} 
                                        value={emailAd}
                                        onChange={(e) => setEmailAd(e.target.value)}
                                    />
                                </div>
                                <div className="col-md-6 form-group">
                                    <input 
                                        type="number" 
                                        placeholder="Phone Number"
                                        className={`form-control ${phoneNumError ? 'is-invalid' : 'is-valid'}`} 
                                        value={phoneNum}
                                        onChange={(e) => setPhoneNum(e.target.value)}
                                    />
                                </div>
                                <div className="col-12 form-group">
                                    <textarea 
                                        placeholder="Message" rows="4"
                                        className={`form-control ${messageError ? 'is-invalid' : 'is-valid'}`} 
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                    ></textarea>
                                </div>
                            </div>
                            <button className="btn">Send Message</button>
                        </form>

                    </div>
                    <div className="col-12 map">
                        <Map />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactLanding
