import farmers from '../../assets/images/home/farmers.png'

const Farmers = () => {
    return (
        <section id="farmers">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-xl-7">
                                <img src={farmers} alt="farmers" className="img-fluid"/>
                            </div>
                            <div className="col-xl-5">
                                <p>“ We care about the animals and the people at the end of the food chain. “</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Farmers
