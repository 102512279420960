import aboutcontent from '../../assets/images/about/aboutcontent.png'

const AboutContent = () => {
    return (
        <section id="aboutContent">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h2 className="subhead">About</h2>
                        <p className="generalPara borderSub">We provide <span> Feed Production Services </span> to our partners through Tolling Arrangements</p>
                        <p className="generalPara">Established in 2008 Gensan Feedmill Inc., is one of the largest feed manufacturer in the Philippines.</p>
                        <p className="subPara">Brgy. Silway 7, Purok Matin-ao, Polomolok, South Cotabato, nearly 12 kilometers from wharf and 27 kilometers from the airport.</p>
                    </div>
                    <div className="col-md-6">
                        <img src={aboutcontent} alt="About Content" className="img-fluid"/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutContent
